import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import Vuex from 'vuex'
import * as echarts from 'echarts';
import "swiper/swiper.min.css"
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
// 隐藏 右侧浏览器 滚动条
Vue.use(VueSmoothScroll)

Vue.use(Antd);
Vue.use(Vuex);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
