import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home, // 原来引用的地址
    redirect: '/Home', // 重定位后页面加载的页面
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

export default router
